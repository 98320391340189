const simpleFlashcards = {
  'opposite-words': [
    {
      level: 1,
      serial: 'ow1-001',
      front: {
        header: 'સસ્તું',
        subheader: {
          hi: 'सस्ता',
          en: 'Cheap',
          de: ''
        }
      },
      back: {
        header: 'મોંઘુ',
        subheader: {
          hi: 'महंगा',
          en: 'Expensive',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-002',
      front: {
        header: 'સાચું',
        subheader: {
          hi: 'सही',
          en: 'TRUE',
          de: ''
        }
      },
      back: {
        header: 'ખોટું',
        subheader: {
          hi: 'गलत',
          en: 'FALSE',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-003',
      front: {
        header: 'પ્રેમ',
        subheader: {
          hi: 'प्रेम',
          en: 'Love',
          de: ''
        }
      },
      back: {
        header: 'નફરત',
        subheader: {
          hi: 'नफरत',
          en: 'Hate',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-004',
      front: {
        header: 'ઠંડું',
        subheader: {
          hi: 'ठंडा',
          en: 'Cold',
          de: ''
        }
      },
      back: {
        header: 'ગરમ',
        subheader: {
          hi: 'गरम',
          en: 'Hot',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-005',
      front: {
        header: 'હસવું',
        subheader: {
          hi: 'हँसना',
          en: 'Laugh',
          de: ''
        }
      },
      back: {
        header: 'રડવું',
        subheader: {
          hi: 'रोना',
          en: 'Cry',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-006',
      front: {
        header: 'ચાલવું',
        subheader: {
          hi: 'चलना',
          en: 'Walk',
          de: ''
        }
      },
      back: {
        header: 'દોડવું',
        subheader: {
          hi: 'दौड़ना',
          en: 'Run',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-007',
      front: {
        header: 'નીચે',
        subheader: {
          hi: 'नीचे',
          en: 'Down',
          de: ''
        }
      },
      back: {
        header: 'ઉપર',
        subheader: {
          hi: 'ऊपर',
          en: 'Up',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-008',
      front: {
        header: 'આજ',
        subheader: {
          hi: 'आज',
          en: 'Today',
          de: ''
        }
      },
      back: {
        header: 'કાલ',
        subheader: {
          hi: 'कल',
          en: 'Tomorrow',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-009',
      front: {
        header: 'સવાર',
        subheader: {
          hi: 'सुबह',
          en: 'Morning',
          de: ''
        }
      },
      back: {
        header: 'સાંજ',
        subheader: {
          hi: 'शाम',
          en: 'Evening',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-010',
      front: {
        header: 'રાત',
        subheader: {
          hi: 'रात',
          en: 'Night',
          de: ''
        }
      },
      back: {
        header: 'દિવસ',
        subheader: {
          hi: 'दिन',
          en: 'Day',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-011',
      front: {
        header: 'સુખી',
        subheader: {
          hi: 'सुखी',
          en: 'Happy',
          de: ''
        }
      },
      back: {
        header: 'દુઃખી',
        subheader: {
          hi: 'दुःखी',
          en: 'Sad',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-012',
      front: {
        header: 'ખુલ્લું',
        subheader: {
          hi: 'खुला',
          en: 'Open',
          de: ''
        }
      },
      back: {
        header: 'બંધ',
        subheader: {
          hi: 'बंद',
          en: 'Close',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-013',
      front: {
        header: 'આવવું',
        subheader: {
          hi: 'आना',
          en: 'Come',
          de: ''
        }
      },
      back: {
        header: 'જવું',
        subheader: {
          hi: 'जाना',
          en: 'Go',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-014',
      front: {
        header: 'ખુલ્લું',
        subheader: {
          hi: 'खुला',
          en: 'Open',
          de: ''
        }
      },
      back: {
        header: 'બંધ',
        subheader: {
          hi: 'बंद',
          en: 'Closed',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-015',
      front: {
        header: 'નવું',
        subheader: {
          hi: 'नया',
          en: 'New',
          de: ''
        }
      },
      back: {
        header: 'જૂનું',
        subheader: {
          hi: 'पुराना',
          en: 'Old',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-016',
      front: {
        header: 'ડાબું',
        subheader: {
          hi: 'बाएँ',
          en: 'Left',
          de: ''
        }
      },
      back: {
        header: 'જમણું',
        subheader: {
          hi: 'दाएँ',
          en: 'Right',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-017',
      front: {
        header: 'કાળું',
        subheader: {
          hi: 'काला',
          en: 'Black',
          de: ''
        }
      },
      back: {
        header: 'ધોળું',
        subheader: {
          hi: 'सफ़ेद',
          en: 'White',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-018',
      front: {
        header: 'ખાલી',
        subheader: {
          hi: 'खाली',
          en: 'Empty',
          de: ''
        }
      },
      back: {
        header: 'ભરેલું',
        subheader: {
          hi: 'पूर्ण',
          en: 'Full of',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-019',
      front: {
        header: 'અંધારું',
        subheader: {
          hi: 'अँधेरा',
          en: 'Dark',
          de: ''
        }
      },
      back: {
        header: 'અજવાળું',
        subheader: {
          hi: 'उजाला',
          en: 'Light',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-020',
      front: {
        header: 'સુંદર',
        subheader: {
          hi: 'सुंदर',
          en: 'Beautiful',
          de: ''
        }
      },
      back: {
        header: 'કદરૂપું',
        subheader: {
          hi: 'बदसूरत',
          en: 'Ugly',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-021',
      front: {
        header: 'આગળ',
        subheader: {
          hi: 'आगे',
          en: 'Ahead',
          de: ''
        }
      },
      back: {
        header: 'પાછળ',
        subheader: {
          hi: 'पीछे',
          en: 'Behind',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-022',
      front: {
        header: 'છોકરો',
        subheader: {
          hi: 'लड़का',
          en: 'Boy',
          de: ''
        }
      },
      back: {
        header: 'છોકરી',
        subheader: {
          hi: 'लड़की',
          en: 'Girl',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-023',
      front: {
        header: 'અંદર',
        subheader: {
          hi: 'अंदर',
          en: 'Inside',
          de: ''
        }
      },
      back: {
        header: 'બહાર',
        subheader: {
          hi: 'बाहर',
          en: 'Outside',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-024',
      front: {
        header: 'ચોખ્ખું',
        subheader: {
          hi: 'साफ़',
          en: 'Clean',
          de: ''
        }
      },
      back: {
        header: 'ગંદું',
        subheader: {
          hi: 'गंदा',
          en: 'Dirty',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'ow1-025',
      front: {
        header: 'દૂર',
        subheader: {
          hi: 'दूर',
          en: 'Far',
          de: ''
        }
      },
      back: {
        header: 'પાસે',
        subheader: {
          hi: 'पास',
          en: 'Near',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-001',
      front: {
        header: 'ભીનું',
        subheader: {
          hi: 'गिला',
          en: 'Wet',
          de: ''
        }
      },
      back: {
        header: 'સુકું',
        subheader: {
          hi: 'सूखा',
          en: 'Dry',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-002',
      front: {
        header: 'ધરતી',
        subheader: {
          hi: 'पृथ्वी',
          en: 'Land',
          de: ''
        }
      },
      back: {
        header: 'આકાશ',
        subheader: {
          hi: 'आकाश',
          en: 'Sky',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-003',
      front: {
        header: 'પૂર્વ',
        subheader: {
          hi: 'पूर्व',
          en: 'East',
          de: ''
        }
      },
      back: {
        header: 'પશ્ચિમ',
        subheader: {
          hi: 'पश्चिम',
          en: 'West',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-004',
      front: {
        header: 'ઉત્તર',
        subheader: {
          hi: 'उत्तर',
          en: 'North',
          de: ''
        }
      },
      back: {
        header: 'દક્ષિણ',
        subheader: {
          hi: 'दक्षिण',
          en: 'South',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-005',
      front: {
        header: 'પાપ',
        subheader: {
          hi: 'पाप',
          en: 'Paap',
          de: ''
        }
      },
      back: {
        header: 'પુણ્ય',
        subheader: {
          hi: 'पुण्य',
          en: 'Punya',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-006',
      front: {
        header: 'જીવન',
        subheader: {
          hi: 'जिंदगी',
          en: 'Birth',
          de: ''
        }
      },
      back: {
        header: 'મૃત્યુ',
        subheader: {
          hi: 'मौत',
          en: 'Death',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-007',
      front: {
        header: 'ગુણ',
        subheader: {
          hi: 'गुण',
          en: 'Virtue',
          de: ''
        }
      },
      back: {
        header: 'દોષ',
        subheader: {
          hi: 'दोष',
          en: 'Vice',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-008',
      front: {
        header: 'ફરજીયાત',
        subheader: {
          hi: 'अनिवार्य',
          en: 'Compulsory',
          de: ''
        }
      },
      back: {
        header: 'મરજીયાત',
        subheader: {
          hi: 'स्वैच्छिक',
          en: 'Voluntary',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-009',
      front: {
        header: 'સ્વર્ગ',
        subheader: {
          hi: 'स्वर्ग',
          en: 'Heaven',
          de: ''
        }
      },
      back: {
        header: 'નર્ક',
        subheader: {
          hi: 'नरक',
          en: 'Hell',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-010',
      front: {
        header: 'હકારાત્મક',
        subheader: {
          hi: 'सकारात्मक',
          en: 'Positive',
          de: ''
        }
      },
      back: {
        header: 'નકારાત્મક',
        subheader: {
          hi: 'नकारात्मक',
          en: 'Negative',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-011',
      front: {
        header: 'પરિચિત',
        subheader: {
          hi: 'परिचित',
          en: 'Known',
          de: ''
        }
      },
      back: {
        header: 'અપરિચિત',
        subheader: {
          hi: 'अपरिचित',
          en: 'Unknown',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-012',
      front: {
        header: 'મૂંગું',
        subheader: {
          hi: 'गूंगा',
          en: 'Dumb',
          de: ''
        }
      },
      back: {
        header: 'બોલકું',
        subheader: {
          hi: 'बातूनी',
          en: 'Talkative',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-013',
      front: {
        header: 'જુવાન',
        subheader: {
          hi: 'यूवा',
          en: 'Young',
          de: ''
        }
      },
      back: {
        header: 'ઘરડું',
        subheader: {
          hi: 'वृध्ध',
          en: 'Old',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-014',
      front: {
        header: 'દુશ્મન',
        subheader: {
          hi: 'दुश्मन',
          en: 'Enemy',
          de: ''
        }
      },
      back: {
        header: 'દોસ્ત',
        subheader: {
          hi: 'दोस्त',
          en: 'Friend',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-015',
      front: {
        header: 'મિત્રતા',
        subheader: {
          hi: 'दोस्ती',
          en: 'Friendship',
          de: ''
        }
      },
      back: {
        header: 'દુશ્મની',
        subheader: {
          hi: 'दुश्मनी',
          en: 'Enmity',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-016',
      front: {
        header: 'નરમ',
        subheader: {
          hi: 'कोमल',
          en: 'Soft',
          de: ''
        }
      },
      back: {
        header: 'કઠણ',
        subheader: {
          hi: 'कठोर',
          en: 'Hard',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-017',
      front: {
        header: 'સુગંધ',
        subheader: {
          hi: 'खुशबू',
          en: 'Fragrance',
          de: ''
        }
      },
      back: {
        header: 'દુર્ગંધ',
        subheader: {
          hi: 'बदबू',
          en: 'Stench',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-018',
      front: {
        header: 'સ્વસ્થ',
        subheader: {
          hi: 'स्वस्थ',
          en: 'Healthy',
          de: ''
        }
      },
      back: {
        header: 'અસ્વસ્થ',
        subheader: {
          hi: 'अस्वस्थ',
          en: 'Unhealthy',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-019',
      front: {
        header: 'સુટેવો',
        subheader: {
          hi: 'अच्छी आदतें',
          en: 'Good Habits',
          de: ''
        }
      },
      back: {
        header: 'કુટેવો',
        subheader: {
          hi: 'बूरी आदतें',
          en: 'Bad Habits',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-020',
      front: {
        header: 'ક્ષણિક',
        subheader: {
          hi: 'अस्थायी',
          en: 'Temporary',
          de: ''
        }
      },
      back: {
        header: 'કાયમી',
        subheader: {
          hi: 'स्थायी',
          en: 'Permanent',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-021',
      front: {
        header: 'જીત',
        subheader: {
          hi: 'विजय',
          en: 'Victory',
          de: ''
        }
      },
      back: {
        header: 'હાર',
        subheader: {
          hi: 'हार',
          en: 'Defeat',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-022',
      front: {
        header: 'અમીર',
        subheader: {
          hi: 'अमीर',
          en: 'Rich',
          de: ''
        }
      },
      back: {
        header: 'ગરીબ',
        subheader: {
          hi: 'गरीब',
          en: 'Poor',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-023',
      front: {
        header: 'સ્વતંત્ર',
        subheader: {
          hi: 'स्वतंत्र',
          en: 'Independent',
          de: ''
        }
      },
      back: {
        header: 'પરતંત્ર',
        subheader: {
          hi: 'परतंत्र',
          en: 'Dependent',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-024',
      front: {
        header: 'દયાળુ',
        subheader: {
          hi: 'दयालू',
          en: 'Kind',
          de: ''
        }
      },
      back: {
        header: 'ક્રૂર',
        subheader: {
          hi: 'क्रूर',
          en: 'Cruel',
          de: ''
        }
      }
    },
    {
      level: 2,
      serial: 'ow2-025',
      front: {
        header: 'પ્રથમ',
        subheader: {
          hi: 'पहला',
          en: 'First',
          de: ''
        }
      },
      back: {
        header: 'અંતિમ',
        subheader: {
          hi: 'अंतिम',
          en: 'Last',
          de: ''
        }
      }
    }
  ],
  'opposite-sentences': [
    {
      level: 1,
      serial: 'os1-001',
      front: {
        header: 'તે સાચો છે.',
        subheader: {
          hi: 'वह सही है।',
          en: 'He is right.',
          de: ''
        }
      },
      back: {
        header: 'તે ખોટો છે.',
        subheader: {
          hi: 'वह गलत है।',
          en: 'He is wrong.',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'os1-002',
      front: {
        header: 'તે રાણી છે.',
        subheader: {
          hi: 'वह रानी है।',
          en: 'She is a queen.',
          de: ''
        }
      },
      back: {
        header: ' તે રાજા છે.',
        subheader: {
          hi: 'वह राजा है।',
          en: 'He is a King.',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'os1-003',
      front: {
        header: 'તે પરિણીત છે.',
        subheader: {
          hi: 'वह शादीशुदा है।',
          en: 'She is married.',
          de: ''
        }
      },
      back: {
        header: 'તે અપરિણીત છે.',
        subheader: {
          hi: 'वह अविवाहित है।',
          en: 'She is unmarried.',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'os1-004',
      front: {
        header: 'આ દાખલો સહેલો છે.',
        subheader: {
          hi: 'यह रकम आसान है।',
          en: 'This sum is easy.',
          de: ''
        }
      },
      back: {
        header: 'આ દાખલો અઘરો છે.',
        subheader: {
          hi: 'यह रकम कठिन है।',
          en: 'This sum is difficult.',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'os1-005',
      front: {
        header: 'અમે પ્રશ્ન પૂછીએ.',
        subheader: {
          hi: 'हम प्रश्न पूछते हैं।',
          en: 'We ask questions.',
          de: ''
        }
      },
      back: {
        header: 'તમે જવાબ આપો.',
        subheader: {
          hi: 'तुम जवाब देना।',
          en: 'You give answers.',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'os1-006',
      front: {
        header: 'ઊભો થા.',
        subheader: {
          hi: 'खड़े हो जाओ।',
          en: 'Stand up.',
          de: ''
        }
      },
      back: {
        header: 'બેસી જાં.',
        subheader: {
          hi: 'बैठ जाओ।',
          en: 'Sit down.',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'os1-007',
      front: {
        header: 'મારે પૈસા લેવાના છે.',
        subheader: {
          hi: 'मुझे पैसे लेने हैं।',
          en: 'I have to take money.',
          de: ''
        }
      },
      back: {
        header: 'મારે પૈસા દેવાના છે.',
        subheader: {
          hi: 'मुझे पैसे देने हैं।',
          en: 'I have to give money.',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'os1-008',
      front: {
        header: 'માતા મહાન છે.',
        subheader: {
          hi: 'माता महान हैं।',
          en: 'Mother is great.',
          de: ''
        }
      },
      back: {
        header: 'પિતા મહાન છે.',
        subheader: {
          hi: 'पिता महान हैं।',
          en: 'Father is great.',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'os1-009',
      front: {
        header: 'આ ફળ મીઠું છે.',
        subheader: {
          hi: 'यह फल मीठा है।',
          en: 'This fruit is sweet.',
          de: ''
        }
      },
      back: {
        header: 'આ ફળ કડવું છે.',
        subheader: {
          hi: 'यह फल कड़वा है।',
          en: 'This fruit is bitter.',
          de: ''
        }
      }
    },
    {
      level: 1,
      serial: 'os1-010',
      front: {
        header: 'તે મારા પતિ છે.',
        subheader: {
          hi: 'वह मेरे पति हैं।',
          en: 'He is my husband.',
          de: ''
        }
      },
      back: {
        header: 'તે મારી પત્ની છે.',
        subheader: {
          hi: 'वह मेरी पत्नी है।',
          en: 'She is my wife.',
          de: ''
        }
      }
    }
  ]
}
export default simpleFlashcards
