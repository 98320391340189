<template>
  <div class="justify-center mb-5" :style="{ width: '100%' }">
    <div class="player">
      <!-- <div
        v-if="preview.title"
        class="font-weight-bold text-xs-h5 text-sm-h4 text-center pa-4"
      >
        {{ preview.title }}
      </div> -->
      <DisplayImage
        v-if="isImage"
        :maxHeight="maxImageHeight"
        :assetPath="preview.id"
        :fileName="preview.id"
        contain
      />
      <Player
        v-if="isVideo"
        :media="{ type: 'youtube', playerId: preview.id }"
        :emit="['ended', 'play', 'pause']"
        @ended="videoEnded"
        @pause="$emit('videoPaused')"
        @play="videoPlay"
        :options="{
          autoplay: true,
          displayDuration: true,
          noCookie: false,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
          modestbranding: 1,
          muted: false,
          settings: ['captions'],
          captions: { active: true }
        }"
        :key="'pl'"
      />
      <audio v-if="isAudio" id="player" controls>
        <source src="../../assets/AsimJayJayKarTune.mp3" type="audio/mp3" />
      </audio>
      <a
        v-if="isPdf"
        class="pdf"
        target="_blank"
        :href="preview.id"
        rel="noopener noreferrer"
      >
        <DisplayImage
          :maxHeight="maxImageHeight"
          :assetPath="preview.image"
          :fileName="preview.image"
          contain
        />

        <div class="text-h6">
          <v-icon
            :medium="$vuetify.breakpoint.smAndDown"
            :large="!$vuetify.breakpoint.smAndDown"
            class="mr-2"
            >mdi-book-open-page-variant</v-icon
          >
          <span>{{ $t('Click here to read') }}</span>
        </div>
      </a>
    </div>
    <!-- preview - button -->
    <div class="text-center mt-10">
      <v-btn raised="true" color="secondary" @click="onNext">{{
        $t(preview.nextBtnText)
      }}</v-btn>
    </div>
  </div>
</template>
<script>
import DisplayImage from '../Image.vue'
import Player from '../Player.vue'
import { mapActions } from 'vuex'

export default {
  components: { DisplayImage, Player },
  props: ['preview'],
  data: () => ({}),
  computed: {
    maxImageHeight() {
      return Math.min(window.innerWidth, window.innerHeight * 0.5)
    },
    isImage() {
      return this.preview.type === 'image'
    },
    isVideo() {
      return this.preview.type === 'video'
    },
    isAudio() {
      return this.preview.type === 'audio'
    },
    isPdf() {
      return this.preview.type === 'pdf'
    }
  },
  methods: {
    ...mapActions(['playBackgroundMusic', 'pauseBackgroundMusic']),
    openPDF() {},
    videoEnded() {
      this.playBackgroundMusic()
      this.$emit('videoEnded')
    },
    videoPlay() {
      this.pauseBackgroundMusic()
      this.$emit('videoPlay')
    },
    onNext() {
      this.playBackgroundMusic()
      this.$emit('onNext')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/global.scss';

.player {
  margin: auto;
  width: 90%;
  text-align: center;
}
.pdf {
  cursor: pointer;
  text-decoration: none;
  color: var(--v-primary-darken1);
  &__icon {
    font-size: 100px !important;
  }
  & i {
    color: inherit !important;
  }
}
</style>
