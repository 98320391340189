<template>
  <div>
    <v-row justify="center">
      <v-carousel
        :continuous="false"
        hide-delimiters
        :style="{ width: $vuetify.breakpoint.mdAndUp ? '450px' : '425px' }"
        class="customArrow"
        height="550px"
        ref="carousel"
      >
        <v-carousel-item
          v-for="(card, i) in flashCards"
          :key="'carouselItem' + card.serial"
        >
          <div class="scene pa-5">
            <div class="card" :ref="'card' + card.serial">
              <audio id="localaudio" @ended="voiceIcon = 'mdi-play'"></audio>
              <div class="card__face card__face--front">
                <FlashCard
                  :cardHeader="card.front.header"
                  :cardSubHeader="card.front.subheader"
                  :cardSpeak="card.front.speak"
                  :imgUrl="card.front.image"
                  :voiceIcon="voiceIcon"
                  @turn="toggleCard('card' + card.serial, i)"
                  @play="play"
                  @openSettings="$emit('openSettings')"
                  :ref="selectedCardType + i + 'front'"
                  :style="
                    card.side !== 'front' && card.side !== 'both'
                      ? { display: 'none' }
                      : {}
                  "
                />
              </div>
              <div class="card__face card__face--back">
                <FlashCard
                  :voiceIcon="voiceIcon"
                  :cardHeader="card.back.header"
                  :cardSubHeader="card.back.subheader"
                  :cardSpeak="card.back.speak"
                  :imgUrl="card.back.image"
                  @turn="toggleCard('card' + card.serial, i)"
                  @play="play"
                  @openSettings="$emit('openSettings')"
                  :style="
                    card.side !== 'back' && card.side !== 'both'
                      ? { display: 'none' }
                      : {}
                  "
                  :ref="selectedCardType + i + 'back'"
                />
              </div>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-row>
    <v-row justify="center">
      <div>
        <v-icon
          medium
          :style="{ width: '25%' }"
          :color="selectedIndex === 0 ? 'gray' : 'primary'"
          @click="previous()"
          >mdi-arrow-left</v-icon
        >
        <v-btn
          text
          :style="{
            width: '50%',
            pointerEvents: 'none',
            textTransform: 'none'
          }"
          >Card {{ selectedIndex + 1 + '/' + cards.length }}</v-btn
        >
        <v-icon
          medium
          :style="{ width: '25%' }"
          :color="selectedIndex === cards.length - 1 ? 'gray' : 'primary'"
          @click="next()"
          >mdi-arrow-right</v-icon
        >
        <GoBackButton />
      </div>
    </v-row>
  </div>
</template>
<script>
import GoBackButton from '../GoBackButton'
import FlashCard from './FlashCard'
import Utils from '../../util/Utils'
import { mapGetters } from 'vuex'
export default {
  components: { GoBackButton, FlashCard },
  props: {
    cards: { type: Array, required: true },
    settings: { type: Object, required: true }
  },
  data: function () {
    return {
      selectedIndex: 0,
      voiceIcon: 'mdi-play',
      defaultVoiceIcon: 'mdi-play',
      flashCards: []
    }
  },
  computed: {
    ...mapGetters(['langs']),
    selectedCardType() {
      return this.$route.params.flashcardType
    },
    languages() {
      return [
        { text: 'English', value: 'en', speechCode: 'en-US' },
        { text: 'हिन्दी', value: 'hi', speechCode: 'hi-IN' },
        { text: 'Deutsch', value: 'de', speechCode: 'de-DE' }
      ]
    }
  },
  mounted() {
    this.changeCards()
  },
  methods: {
    calculatedImageId(card) {
      return Utils.imgUrlByPath(`flashcards/${card.imgUrl || card.serial}.jpg`)
    },
    changeCards() {
      this.flashCards = this.cards.map((c) => {
        return { ...c, side: 'front' }
      })
      this.selectedIndex = 0
      if (this.flashCards[this.selectedIndex].side === 'back') {
        this.toggleCard('card' + this.flashCards[this.selectedIndex].serial)
      }

      while (this.$refs.carousel.hasPrev) {
        this.$refs.carousel.prev()
      }
    },
    displayedText() {
      return this.flashCards[this.selectedIndex][
        this.flashCards[this.selectedIndex].side
      ].header
    },
    speechText() {
      return (
        this.flashCards[this.selectedIndex][
          this.flashCards[this.selectedIndex].side
        ].speak ||
        this.flashCards[this.selectedIndex][
          this.flashCards[this.selectedIndex].side
        ].header
      )
    },
    toggleSettings() {
      const name =
        this.selectedCardType +
        this.selectedIndex +
        this.flashCards[this.selectedIndex].side
      this.openSettings = !this.openSettings

      if (this.openSettings) {
        const height = this.$refs[name][0].$el.clientHeight + 50
        setTimeout(() => {
          this.settingsCardHeight = height
        }, 400)
      }
    },
    toggleCard: function (cardNum, index) {
      var audio = document.getElementById('localaudio')
      audio.pause()
      this.voiceIcon = this.defaultVoiceIcon
      const currentSide = this.flashCards[index].side
      this.flashCards[index].side = 'both'
      this.$refs[cardNum][0].classList.toggle('flip')
      setTimeout(() => {
        if (currentSide === 'front') {
          this.flashCards[index].side = 'back'
        } else {
          this.flashCards[index].side = 'front'
        }
      }, 500)
    },
    next() {
      const next = document.querySelector('.v-window__next button')
      if (next) {
        next.click()
      }
      if (this.selectedIndex + 1 < this.flashCards.length) {
        if (this.flashCards[this.selectedIndex].side !== 'front') {
          this.toggleCard(
            'card' + this.flashCards[this.selectedIndex].serial,
            this.selectedIndex
          )
        }
        this.selectedIndex++
        this.flashCards[this.selectedIndex].side = 'front'
      }
    },
    previous() {
      const prev = document.querySelector('.v-window__prev button')
      if (prev) {
        prev.click()
      }
      if (this.selectedIndex > 0) {
        if (this.flashCards[this.selectedIndex].side !== 'front') {
          this.toggleCard(
            'card' + this.flashCards[this.selectedIndex].serial,
            this.selectedIndex
          )
        }
        this.selectedIndex--
        this.flashCards[this.selectedIndex].side = 'front'
      }
    },
    play() {
      this.voiceIcon = 'mdi-pause'
      var audio = document.getElementById('localaudio')
      audio.src = `https://us-central1-learn-gujarati-b1468.cloudfunctions.net/tts?text=${this.speechText(
        this.selectedIndex
      )}&lang=${
        this.flashCards[this.selectedIndex][
          this.flashCards[this.selectedIndex].side
        ].speechCode
      }&speed=${this.settings.voiceSpeed}&gender=${this.settings.voiceGender}`
      audio.play()
    }
  },
  watch: {
    cards(newValue) {
      this.changeCards()
    }
  }
}
</script>
<style scoped>
.scene {
  width: 100%;
  height: 100%;
  margin: 40px 0;
  perspective: 1200px;
}
.card {
  position: relative;
  width: 100%;
  height: 68%;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}
.flip {
  transform: rotateY(-180deg);
}
.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 260px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  backface-visibility: hidden;
}
.card__face__card {
  backface-visibility: hidden;
}
.card__face--front {
}
.card__face--back {
  transform: rotateY(180deg);
}
.voice {
  border-radius: 12px;
  box-shadow: 0 0 0 0 rgba(217, 84, 89, 1);
  animation: pulse-red 2s infinite;
}
@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(217, 84, 89, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(217, 84, 89, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(217, 84, 89, 0);
  }
}
.settings {
  position: absolute;
  content: '';
  bottom: 0;
  width: 100%;
  height: 18%;
  color: #fff;
  transition: all 0.4s;
  overflow: hidden;
  pointer-events: none;
  left: 0;
  backface-visibility: hidden;
}
.settings:before {
  position: absolute;
  content: '';
  left: 93%;
  bottom: 0px;
  width: 100%;
  height: 104%;
  z-index: 0;
  opacity: 1;
  color: #fff;
  background-color: #030303;
  transition: all 0.4s;
  transform: skewX(-50deg);
  backface-visibility: hidden;
}
.settingsIcon {
  position: absolute;
  right: 5px;
  z-index: 0;
  bottom: 6px;
}
.settingsIcon:hover {
  animation: infinite-spinning 1s ease-out 0s normal;
}
@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.settingsCard {
  position: absolute;
  z-index: 1;
}
.settingsCardClose {
  position: absolute;
  bottom: 10px;
  left: 36%;
}
/** Hide carousel arrows */
.customArrow >>> .v-window__prev {
  left: 62px;
  z-index: -1;
  background: none;
}
.customArrow >>> .v-window__next {
  right: 62px;
  z-index: -1;
  background: none;
}
</style>
