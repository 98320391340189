import Vue from 'vue'
import Vuex from 'vuex'
import wordList from './data'
import letters from './letters'
import vowels from './vowels'
import fortuneWheel from './fortuneWheel'
import listenSpeak from './listenSpeak'
import jointWords from './jointwords'
import sentences from './sentences'
import numbers from './numbers'
import sentenceForming from './sentenceforming'
import interrogatives from './interrogatives'
import fillBlanksColors from './fillblankscolors'
import quizzes from './quizzes'
import prepositionsConjunctions from './prepositionsconjunctions'
import studyMaterial from './studymaterial'
import flashcards from './flashcards'
import simpleFlashcards from './simple-flashcards'
import jointLetters from './joinletters'
import identifyTenses from './identifytenses'
import genders from './genders'
import oddOneOut from './oddOneOut'
import pronouns from './pronouns'
import singularPlural from './singularPlural'
import tensesPast from './tensesPast'
import tensesPresent from './tensesPresent'
import tensesFuture from './tensesFuture'
import PracticePlayCategories from './PracticePlayCategories'
import practicePlayPages from './PracticePlayPages'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    wordList: wordList,
    letters: letters,
    vowels: vowels,
    fortuneWheel: fortuneWheel,
    listenSpeak: listenSpeak,
    jointWords: jointWords,
    selectedLanguage: 'eng',
    sentences: sentences,
    numbers: numbers,
    sentenceForming: sentenceForming,
    interrogatives: interrogatives,
    fillBlanksColors: fillBlanksColors,
    quizzes: quizzes,
    prepositionsConjunctions: prepositionsConjunctions,
    studyMaterial: studyMaterial,
    flashcards: flashcards,
    simpleFlashcards: simpleFlashcards,
    version: process.env.VUE_APP_VERSION,
    jointLetters: jointLetters,
    identifyTenses: identifyTenses,
    genders: genders,
    oddOneOut: oddOneOut,
    pronouns: pronouns,
    singularPlural: singularPlural,
    tensesPast: tensesPast,
    tensesPresent: tensesPresent,
    tensesFuture: tensesFuture,
    practicePlayCategories: PracticePlayCategories,
    practicePlayPages: practicePlayPages,
    loading: false,
    sound: '',
    backgroundMusic: { src: '', audioPlayer: undefined, volume: '50' }
  },
  getters: {
    wordList: (state) => {
      return state.wordList
    },
    langs: (state) => {
      return {
        en: {
          text: 'English',
          desc: 'eng',
          selectedDisplay: 'EN',
          displayOrder: 0,
          speechCode: 'en-US'
        },
        hi: {
          text: 'हिन्दी',
          desc: 'hindi',
          displayOrder: 1,
          speechCode: 'hi-IN'
        },
        gu: {
          text: 'ગુજરાતી',
          desc: 'guj',
          displayOrder: 2,
          speechCode: 'gu-IN'
        }
      }
    },
    supportedLangs: (state, getters) => {
      return {
        ...getters.langs,
        de: {
          text: 'Deutsch',
          desc: 'Deutsch',
          displayOrder: 3,
          speechCode: 'de-DE'
        }
      }
    },
    loading: (state) => {
      return state.loading
    },
    listenSpeak: (state) => {
      return state.listenSpeak
    },
    sound: (state) => {
      return state.sound
    },
    backgroundMusic: (state) => {
      return state.backgroundMusic
    }
  },
  mutations: {
    startLoading: (state) => {
      state.loading = true
    },
    endLoading: (state) => {
      state.loading = false
    },
    playSound: (state, payload) => {
      state.sound = payload
    },
    backgroundMusic: (state, payload) => {
      state.backgroundMusic = payload
    }
  },
  actions: {
    playSound: ({ commit }, payload) => {
      commit('playSound', payload)
    },
    startBackgroundMusic: ({ commit, state }, payload) => {
      if (payload && state.backgroundMusic?.src !== payload) {
        const temp = { src: payload, audioPlayer: undefined, volume: '12.5' }
        temp.audioPlayer = new Audio(payload)
        temp.audioPlayer.loop = true
        temp.audioPlayer.volume = temp.volume / 100
        temp.audioPlayer.play()
        commit('backgroundMusic', temp)
      }
    },
    removeBackgroundMusic: ({ commit, state }) => {
      state.backgroundMusic?.audioPlayer &&
        (state.backgroundMusic.audioPlayer.muted = true)
      commit('backgroundMusic', {
        src: '',
        audioPlayer: undefined,
        volume: '12.5'
      })
    },
    muteUnmuteBackgroundMusic: ({ state }, payload) => {
      if (state.backgroundMusic.audioPlayer) {
        state.backgroundMusic.audioPlayer.muted = payload
      }
    },
    playBackgroundMusic: ({ state }) => {
      state.backgroundMusic?.audioPlayer?.play()
    },
    pauseBackgroundMusic: ({ state }) => {
      state.backgroundMusic?.audioPlayer?.pause()
    },
    changeVolumeOfBackgroundMusic({ state }, payload) {
      state.backgroundMusic.volume = payload
      state.backgroundMusic.audioPlayer &&
        (state.backgroundMusic.audioPlayer.volume = payload / 100)
    }
  },
  modules: {}
})
