<template>
  <div>
    <v-dialog
      persistent
      transition="dialog-top-transition"
      v-model="visible"
      max-width="400"
    >
      <v-card shaped light>
        <v-card-title> Settings </v-card-title>
        <v-card-text class="pl-10 pr-10">
          <v-row>
            <v-select
              v-model="settings.level"
              :items="levels"
              item-text="type"
              item-value="value"
              label="Select Level"
            ></v-select>
          </v-row>
          <v-row>
            <v-select
              v-model="settings.lang"
              :items="languages"
              item-text="text"
              item-value="value"
              label="Select Language"
            ></v-select>
          </v-row>
          <v-row>
            <v-select
              v-model="settings.voiceSpeed"
              :items="voiceSpeedPreference"
              item-text="speed"
              item-value="value"
              label="Select Voice Speed"
            ></v-select>
          </v-row>
          <v-row>
            <v-select
              v-model="settings.voiceGender"
              :items="voiceGenderPreference"
              item-text="gender"
              item-value="value"
              label="Select Voice Gender"
            ></v-select>
          </v-row>
          <v-row>
            <span>Practice In Reverse</span>
            <v-btn-toggle
              class="pl-2 pr-2"
              v-model="settings.reverse"
              :active-class="'answerLangToggle'"
              rounded
              mandatory
            >
              <v-btn class="font-weight-bold" small> No </v-btn>
              <v-btn class="font-weight-bold" small> Yes </v-btn>
            </v-btn-toggle>
          </v-row>
        </v-card-text>

        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            raised
            color="primary"
            class="mr-5"
            @click="changeSettings()"
            >Ok</v-btn
          >
          <v-btn
            large
            text
            raised
            color="primary"
            class="mr-5"
            @click="$emit('cancel')"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    levels: { type: Array, required: true },
    visible: { type: Boolean, required: true },
    defaultSettings: { type: Object, required: true },
    isGermanSupported: { type: Boolean, default: true }
  },
  data: function () {
    return {
      voiceSpeedPreference: [
        { speed: 'Slow', value: 0.5 },
        { speed: 'Normal', value: 0.75 },
        { speed: 'Fast', value: 1 }
      ],
      voiceGenderPreference: [
        { gender: 'Male', value: 'MALE' },
        { gender: 'Female', value: 'FEMALE' }
      ],

      settings: {
        level: '',
        lang: '',
        voiceGender: 'FEMALE',
        voiceSpeed: 0.75,
        reverse: 1
      }
    }
  },
  computed: {
    languages() {
      const langs = [
        { text: 'English', value: 'en', speechCode: 'en-US' },
        { text: 'हिन्दी', value: 'hi', speechCode: 'hi-IN' }
      ]
      console.log(langs)
      if (this.isGermanSupported) {
        langs.push({ text: 'Deutsch', value: 'de', speechCode: 'de-DE' })
      }
      return langs
    },
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  mounted() {
    this.settings = {
      ...this.defaultSettings,
      reverse: this.defaultSettings.reverse ? 1 : 0
    }
  },
  methods: {
    changeSettings() {
      this.$emit('changeSettings', {
        ...this.settings,
        reverse: this.settings.reverse === 0 ? false : true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.answerLangToggle {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}
</style>
