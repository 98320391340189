<template>
  <v-card shaped light class="ma-2 pb-5 card__face__card">
    <v-img v-if="imgUrl" height="170" cover :src="imgUrl"></v-img>
    <v-card-actions class="justify-center mt-5">
      <v-btn
        color="primary"
        fab
        small
        dark
        class="text-center"
        @click="$emit('play')"
      >
        <v-icon>{{ voiceIcon }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-card-text>
      <div class="my-4 text-h5 text-md-h4 text-center">
        {{ cardHeader }}
      </div>
      <div class="my-4 text-h6 text-center">
        {{ cardSubHeader }}
      </div>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn rounded color="primary" class="text-center" @click="$emit('turn')">
        <v-icon left> mdi-rotate-3d-variant </v-icon>
        Turn
      </v-btn>
    </v-card-actions>

    <a title="settings" class="settings" />
    <v-icon class="settingsIcon" color="white" @click="$emit('openSettings')">
      mdi-cog-outline
    </v-icon>
  </v-card>
</template>
<script>
export default {
  props: ['cardHeader', 'cardSubHeader', 'cardSpeak', 'imgUrl', 'voiceIcon']
}
</script>
<style scoped>
.settings {
  position: absolute;
  content: '';
  bottom: 0;
  width: 100%;
  height: 18%;
  color: #fff;
  transition: all 0.4s;
  overflow: hidden;
  pointer-events: none;
  left: 0;
  backface-visibility: hidden;
}

.settings:before {
  position: absolute;
  content: '';
  left: 93%;
  bottom: 0px;
  width: 100%;
  height: 104%;
  z-index: 0;
  opacity: 1;
  color: #fff;
  background-color: #030303;
  transition: all 0.4s;
  transform: skewX(-50deg);
  backface-visibility: hidden;
}
.settingsIcon {
  position: absolute;
  right: 5px;
  z-index: 0;
  bottom: 6px;
}
.settingsIcon:hover {
  animation: infinite-spinning 1s ease-out 0s normal;
}
@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.settingsCard {
  position: absolute;
  z-index: 1;
}
.settingsCardClose {
  position: absolute;
  bottom: 10px;
  left: 36%;
}
</style>
