<template>
  <div class="volumeSection ml-3 mr-3">
    <RoundSlider
      v-model="volume"
      :rangeColor="$vuetify.theme.themes.light.primary.base"
      lineCap="butt"
      borderWidth="0"
      handleSize="10"
      radius="25"
      width="5"
      :min="min"
      :max="max"
      :startAngle="startAngle"
      :endAngle="endAngle"
      :showTooltip="false"
      :valueChange="volumeChange"
    />
    <v-icon
      v-if="!muted"
      :style="{ position: 'absolute' }"
      color="primary"
      medium
      @click="toggleAudioEffects()"
      >mdi-volume-high</v-icon
    >
    <v-icon
      v-else
      medium
      :style="{ position: 'absolute' }"
      color="primary"
      @click="toggleAudioEffects()"
      >mdi-volume-off</v-icon
    >
  </div>
</template>

<script>
import RoundSlider from 'vue-round-slider'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    RoundSlider
  },
  props: {
    min: {
      type: String,
      default: '0'
    },
    max: {
      type: String,
      default: '25'
    },
    default: {
      type: String,
      default: '12.5'
    },
    startAngle: {
      type: String,
      default: '-45'
    },
    endAngle: {
      type: String,
      default: '225'
    },
    backgroundMusicSrc: {
      type: String,
      default: ''
    },
    pauseBackgroundMusic: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['sound', 'backgroundMusic'])
  },
  mounted() {
    this.volume = this.default
    const soundsContext = require.context(
      '../../assets/sounds',
      true,
      /\.(mp3|wav)$/
    )
    soundsContext.keys().forEach((asset) => {
      this.sounds[
        asset.replace('./', '').replace('.wav', '').replace('.mp3', '')
      ] = new Audio(soundsContext(asset))
    })

    this.backgroundMusic?.audioPlayer &&
      (this.muted = this.backgroundMusic.audioPlayer.muted)

    if (this.backgroundMusic && this.backgroundMusic.audioPlayer) {
      this.volume = this.backgroundMusic.volume
      this.muted = this.backgroundMusic.audioPlayer.muted
    }
  },

  data() {
    return {
      muted: false,
      volume: '12.5',
      sounds: {}
    }
  },
  methods: {
    ...mapActions([
      'playSound',
      'muteUnmuteBackgroundMusic',
      'changeVolumeOfBackgroundMusic',
      'startBackgroundMusic',
      'removeBackgroundMusic'
    ]),
    toggleAudioEffects() {
      this.muted = !this.muted
      this.muteUnmuteBackgroundMusic(this.muted)
    },
    volumeChange() {
      this.changeVolumeOfBackgroundMusic(this.volume)
      Object.values(this.sounds).forEach((s) => (s.volume = this.volume / 100))
    }
  },
  watch: {
    sound(newValue) {
      if (!this.backgroundMusic?.audioPlayer?.muted && newValue)
        this.sounds[newValue]?.play()
      this.playSound('')
    }
  }
}
</script>

<style scoped>
.volumeSection {
  text-align: center;
  justify-content: center;
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 15px;
  align-items: center;
}
.volumeSection /deep/ .rs-path {
  stroke-width: 4px;
  stroke-dasharray: 2, 3;
  opacity: 0.7;
  stroke: var(--v-primary-base) !important;
  height: 46px;
  width: 46px;
}
</style>
