import { async } from 'q'
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { firebaseAnalytics } from '../firebase'
import i18n from '../i18n'
Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/practice/fun-games/jumbledWordPuzzle',
    name: 'jumbledWordPuzzle',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "jumbledwordpuzzle" */ '../components/JumbledWordPuzzle.vue'
      )
  },
  {
    path: '/practice/fun-games/wordsearch',
    name: 'wordsearch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "wordsearch" */ '../components/WordSearch.vue'
      )
  },
  {
    path: '/practice/fun-games/paintdada',
    name: 'paintdada',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "paintdada" */ '../components/PaintDada.vue')
  },
  // {
  //   path: '/practice/fun-games/crossword',
  //   name: 'crossword',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "crossword" */ '../components/Crossword.vue')
  // },
  {
    path: '/practice/building-blocks/learnkakko',
    name: 'learnkakko',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "learnkakko" */ '../components/LearnKakko.vue'
      )
  },
  {
    path: '/practice/building-blocks/learnbarakhadi',
    name: 'learnbarakhadi',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "learnbarakhadi" */ '../components/LearnBarakhadi.vue'
      )
  },
  {
    path: '/practice/building-blocks/jointLetters',
    name: 'jointLetters',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "jointletters" */ '../components/JointLetters.vue'
      )
  },

  {
    path: '/practice/flashcards/opposite-words',
    name: 'simpleflashcards',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "flashcards" */ '../components/flashcards/SimpleFlashCards.vue'
      )
  },
  {
    path: '/practice/building-blocks/oddoneout',
    name: 'oddOneOut',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/OddOneOut.vue')
  },
  {
    path: '/practice/flashcards/opposite-sentences',
    name: 'simpleflashcards',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "flashcards" */ '../components/flashcards/SimpleFlashCards.vue'
      )
  },
  {
    path: '/practice/flashcards/:flashcardType',
    name: 'flashcards',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "flashcards" */ '../components/flashcards/LanguageFlashCards.vue'
      )
  },
  {
    path: '/practice/vocabulary/matchwords',
    name: 'matchwords',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "matchwords" */ '../components/MatchWords.vue'
      )
  },
  {
    path: '/practice/vocabulary/buildword',
    name: 'buildword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "buildword" */ '../components/BuildWord.vue')
  },

  {
    path: '/practice/vocabulary/jointWords',
    name: 'jointWords',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "jointwords" */ '../components/JointWords.vue'
      )
  },

  {
    path: '/practice/vocabulary/matchjointwords',
    name: 'matchjointwords',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "matchjointwords" */ '../components/MatchJointWords.vue'
      )
  },
  {
    path: '/practice/basic-grammar/sentenceforming',
    name: 'sentenceforming',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "sentenceforming" */ '../components/SentenceForming.vue'
      )
  },
  {
    path: '/practice/basic-grammar/interrogatives',
    name: 'interrogatives',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "interrogatives" */ '../components/Interrogatives.vue'
      )
  },

  {
    path: '/practice/phrases/matchtrimantra',
    name: 'matchtrimantra',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "matchtrimantra" */ '../components/MatchTrimantra.vue'
      )
  },

  {
    path: '/practice/phrases/matchnavkalamo',
    name: 'matchnavkalamo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "matchnavkalamo" */ '../components/MatchNavKalamo.vue'
      )
  },
  {
    path: '/practice/phrases/sentenceconstruction',
    name: 'sentenceconstruction',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "sentenceconstruction" */ '../components/SentenceConstruction.vue'
      )
  },
  {
    path: '/practice/numbers-and-colors/colors-fill-blanks',
    name: 'colors-fill-blanks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "fillintheblanksForTenses" */ '../components/FillBlanksColors.vue'
      )
  },
  {
    path: '/practice/numbers-and-colors/matchcolors',
    name: 'matchcolors',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "matchcolors" */ '../components/MatchColors.vue'
      )
  },
  {
    path: '/practice/numbers-and-colors/fillintheblanksForNumbers',
    name: 'fillintheblanksForNumbers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "fillintheblanksfornumbers" */ '../components/FillInTheBlanksForNumbers.vue'
      )
  },
  {
    path: '/practice/numbers-and-colors/matchnumbers',
    name: 'matchnumbers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "matchnumbers" */ '../components/MatchNumbers.vue'
      )
  },
  {
    path: '/practice/basic-grammar/genders',
    name: 'genders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "genders" */ '../components/Genders.vue')
  },
  {
    path: '/practice/basic-grammar/singular-plural',
    name: 'singular-plural',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "singular-plural" */ '../components/SingularPlural.vue'
      )
  },
  {
    path: '/practice/basic-grammar/pronouns',
    name: 'pronouns',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "pronouns" */ '../components/Pronouns.vue')
  },
  {
    path: '/practice/basic-grammar/past-tense',
    name: 'past-tense',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "past-tense" */ '../components/TensesPast.vue'
      )
  },
  {
    path: '/practice/basic-grammar/present-tense',
    name: 'present-tense',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "present-tense" */ '../components/TensesPresent.vue'
      )
  },
  {
    path: '/practice/basic-grammar/future-tense',
    name: 'future-tense',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "future-tense" */ '../components/TensesFuture.vue'
      )
  },
  {
    path: '/practice/basic-grammar/identifytenses',
    name: 'identifytenses',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "identifytenses" */ '../components/IdentifyTenses.vue'
      )
  },

  {
    path: '/practice/basic-grammar/useful-words',
    name: 'useful-words',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "useful-words" */ '../components/PrepositionsConjunctions.vue'
      )
  },
  {
    path: '/practice/prayer',
    name: 'prayer',
    meta: {
      hideBreadcrumbOnMainPage: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "prayer" */ '../views/Prayer.vue')
  },
  {
    path: '/study-material',
    name: 'study-material',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "studymaterial" */ '../components/StudyMaterial.vue'
      )
  },

  {
    path: '/practice/spoken/fortune-wheel',
    name: 'fortune-wheel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/FortuneWheel.vue')
  },
  {
    path: '/practice/spoken/listen-speak',
    name: 'listen-speak',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ListenAndSpeak.vue')
  },
  {
    path: '/practice/quiz/ghantnaad',
    name: 'ghantnaad',
    meta: {
      hideBreadcrumbOnMainPage: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Quiz.vue')
  },

  {
    path: '/practice/quizzes/:quizTitle',
    name: 'quizzes-quiz',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Quiz.vue')
  },
  {
    path: '/practice/satsang-interpretation',
    name: 'satsang-interpretation',
    meta: {
      hideBreadcrumbOnMainPage: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "satsang-interpretation" */ '../views/SatsangInterpretation.vue'
      )
  },
  {
    path: '/practice/satsang-interpretation/:quizTitle',
    name: 'satsang-interpretation-quiz',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Quiz.vue')
  },
  {
    path: '/practice/nishchay',
    name: 'nishchay',
    meta: {
      hideBreadcrumbOnMainPage: true
    },
    // beforeEnter() {
    //   window.open(
    //     'https://drive.google.com/file/d/15dra-HnIr1SWYBaszuZZ37lyZClojdm1/view?usp=drivesdk',
    //     '_blank'
    //   )
    // },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "satsang-interpretation" */ '../views/Nishchay.vue'
      )
  },
  {
    path: '/practice/:categoryId',
    name: 'practice-and-play-category',
    meta: {
      hideBreadcrumbOnMainPage: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "practice-and-play-category" */ '../views/PracticePlayCommonPage.vue'
      )
  },
  {
    path: '*',
    name: 'PageNotFound',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth' }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
router.beforeEach(async (to, from, next) => {
  console.log('to', to)
  document.title = 'Learn Gujarati | ' + i18n.t(to.name)
  firebaseAnalytics.logEvent('screen_view', { type: 'internal' })
  firebaseAnalytics.logEvent('page_view', {
    type: 'internal',
    page_location: to.name
  })
  if (to.name != 'Home') {
    store.commit('startLoading')

    setTimeout(() => {
      store.commit('endLoading')
      next()
    }, 750)
  } else {
    next()
  }
})
export default router
