<template>
  <div>
    <FlashCards
      :cards="cards"
      :settings="settings"
      @openSettings="openSettingsDialog = true"
    >
    </FlashCards>
    <FlashcardSettings
      v-if="openSettingsDialog"
      :levels="levels"
      :defaultSettings="settings"
      :visible="openSettingsDialog"
      @changeSettings="updateSettingsAndCloseSettingsDialog"
      @cancel="openSettingsDialog = false"
    />
  </div>
</template>
<script>
import FlashCards from './FlashCards1'
import FlashcardSettings from './FlashcardSettings.vue'
import Utils from '../../util/Utils'
import { mapGetters } from 'vuex'
export default {
  components: { FlashCards, FlashcardSettings },
  data: function () {
    return {
      cards: [
        {
          front: {
            header: '',
            subheader: '',
            image: '',
            speechCode: '',
            speak: ''
          },
          back: {
            header: '',
            subheader: '',
            image: '',
            speechCode: '',
            speak: ''
          }
        }
      ],
      settings: {},
      openSettingsDialog: true
    }
  },
  computed: {
    ...mapGetters(['supportedLangs']),

    selectedCardType() {
      return this.$store.state.practicePlayPages.find(
        (p) =>
          p.categoryId === 'flashcards' &&
          p.id === this.$route.params.flashcardType
      )?.id
    },
    cardByLevels() {
      return Utils.groupBy(
        this.$store.state.flashcards[this.selectedCardType],
        (card) => card.level
      )
    },
    levels() {
      const levels = []
      this.cardByLevels.forEach((values, keys) => {
        levels.push({ type: keys, value: keys })
      })
      return levels
    },

    frontCardLang() {
      return this.reverse ? 'gu' : this.selectedLanguage
    },
    backCardLang() {
      return this.reverse ? this.selectedLanguage : 'gu'
    }
  },
  beforeMount() {
    this.selectedLevel = this.levels[0].value
    this.updateSettings({
      level: this.levels[0].value,
      reverse: false, //false=guj in front true=guj in back
      lang: 'en',
      voiceGender: 'FEMALE',
      voiceSpeed: 0.75
    })
    this.buildCards()
  },
  methods: {
    updateSettings(newSettings) {
      this.settings = {
        level: newSettings.level,
        reverse: newSettings.reverse, //false=guj in front true=guj in back
        lang: newSettings.lang,
        voiceGender: newSettings.voiceGender,
        voiceSpeed: newSettings.voiceSpeed
      }
      // this.reverseDisabledText = !newSettings.reverse
      //   ? `${this.supportedLangs[newSettings.lang].text} to ગુજરાતી`
      //   : `ગુજરાતી to ${this.supportedLangs[newSettings.lang].text}`

      // this.reverseEnabledText = newSettings.reverse
      //   ? `ગુજરાતી to ${this.supportedLangs[newSettings.lang].text}`
      //   : `${this.supportedLangs[newSettings.lang].text} to ગુજરાતી`

      this.buildCards()
    },
    updateSettingsAndCloseSettingsDialog(newSettings) {
      this.updateSettings(newSettings)
      this.openSettingsDialog = false
    },
    buildCards() {
      this.cards = this.cardByLevels.get(this.settings.level).map((card) => {
        const frontCardLang = this.settings.reverse ? 'gu' : this.settings.lang
        const backCardLang = this.settings.reverse ? this.settings.lang : 'gu'

        return {
          serial: card.serial,
          front: {
            header: card[frontCardLang].header,
            subheader: card[frontCardLang].subheader,
            image: this.calculatedImageId(card),
            speechCode: this.supportedLangs[frontCardLang].speechCode,
            speak: card[frontCardLang].speak
          },
          back: {
            header: card[backCardLang].header,
            subheader: card[backCardLang].subheader,
            image: this.calculatedImageId(card),
            speechCode: this.supportedLangs[backCardLang].speechCode,
            speak: card[backCardLang].speak
          }
        }
      })
    },

    calculatedImageId(card) {
      return Utils.imgUrlByPath(`flashcards/${card.imgUrl || card.serial}.jpg`)
    }
  }
}
</script>
<style scoped></style>
